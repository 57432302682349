import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import CardDeck from "react-bootstrap/CardDeck"
import Card from "react-bootstrap/Card"

const Featured = () => {
  const images = useStaticQuery(graphql`
    query {
      espresso: file(relativePath: { eq: "espresso.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 650) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      coffee: file(relativePath: { eq: "regular-coffee.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 650) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      cup: file(relativePath: { eq: "coffee-in-cup.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 650) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <CardDeck className="my-5">
      <Card>
        <Img
          className="card-img-top"
          fluid={images.espresso.childImageSharp.fluid}
        />
        <Card.Body>
          <Card.Title>Runt & mustigt</Card.Title>
          <Card.Text>
            Mustiga smaker med inslag av choklad, såväl som sötma
            av olika slag. Kraftig smak från bönor odlat på över 1200 meter.
          </Card.Text>
          <Card.Text>
            <Link to="/runt-mustigt">Läs mer &raquo;</Link>
          </Card.Text>
          <button
            className="snipcart-add-item btn btn-outline-secondary"
            data-item-id="espresso"
            data-item-name="Runt & mustigt"
            data-item-price="299"
            data-item-image={images.espresso.childImageSharp.fluid.src}
            data-item-url="/"
          >
            Köp 3 månader för 299 SEK
          </button>
          {/* <Button variant="primary">Purchase</Button> */}
        </Card.Body>
      </Card>
      <Card>
        <Img
          className="card-img-top"
          fluid={images.coffee.childImageSharp.fluid}
        />
        <Card.Body>
          <Card.Title>Fruktigt & syrligt</Card.Title>
          <Card.Text>
            Syrliga och fruktiga smaker med inslag från allt ifrån citrus, bär
            och druvor. Från bönor odlat på 1800 meter.
          </Card.Text>
          <button
            className="snipcart-add-item btn btn-outline-secondary"
            data-item-id="vanligt"
            data-item-name="Fruktigt & syrligt"
            data-item-price="399"
            data-item-image={images.coffee.childImageSharp.fluid.src}
            data-item-url="/"
          >
            Köp 3 månader för 399 SEK
          </button>
          {/* <Button variant="primary">Purchase</Button> */}
        </Card.Body>
      </Card>
      <Card>
        <Img
          className="card-img-top"
          fluid={images.cup.childImageSharp.fluid}
        />
        <Card.Body>
          <Card.Title>Explorer</Card.Title>
          <Card.Text>
            Prova exklusivt kaffe från världens alla olika hörs. Du får 100 gram
            kaffe direkt i brevlådan varannan vecka. För dig som vill utforska
            alla goda karaktärer.
          </Card.Text>
          <button
            className="snipcart-add-item btn btn-outline-secondary"
            data-item-id="eko"
            data-item-name="Explorer"
            data-item-price="799"
            data-item-image={images.cup.childImageSharp.fluid.src}
            data-item-url="/"
          >
            Köp 3 månader för 799 SEK
          </button>
          {/* <Button variant="primary">Purchase</Button> */}
        </Card.Body>
      </Card>
    </CardDeck>
  )
}
export default Featured
