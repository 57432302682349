import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Works from "../components/works"
import Featured from "../components/featured"

const SmakerPage = () => (
  <Layout hero={false}>
    <SEO title="Våra smakpaket" />
    <h1>Våra utsökta smakpaket</h1>
    <p>Kaffe i brevlådan en gång varje månad, nya spännande sorter valda med omsorg.</p>
    <Featured />
    <Works />
  </Layout>
)

export default SmakerPage
